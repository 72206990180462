import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Heading from "../components/heading"
import Featured from "../components/featured"
import FeaturedDetails from "../components/featured-details"
import CallToAction from "../components/call-to-action"
import GameDetails from "../components/game-details"
import SEO from "../components/seo"

const styles = createStyles({
  headingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 50,
    "@media (max-width: 599.95px)": {
      padding: "40px 20px",
    },
  },
})

type PropsAndStyles = WithStyles<typeof styles>

const GamesPage = ({ classes }: PropsAndStyles) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            games {
              name
              slogan
              description
              videoThumbnailPath
              videoPath
              logoPath
              appStoreLogoPath
              googlePlay
              appleStore
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Hero
        imagePath="hero/games_01.jpg"
        title="Volt"
        coloredTitle="Games"
        description="Discover our titles"
      />

      <div className={classes.headingContainer}>
        <Heading
          title="Play our Games"
          coloredWords={1}
          description="We develop games for players to enjoy. Our quality-driven, creativity-first approach has allowed us to release titles with impressive results."
        />
      </div>

      {/* <Featured
        imagePath="featured/prom_volt_02.jpg"
        logoPath="games/agent-volt/logo.png"
      >
        <FeaturedDetails
          title="Our new acclaimed Football game is out now!"
          description="Check it out in the Play stores!"
          callToAction={<CallToAction text="Play now!" />}
        />
      </Featured> */}

      {site.siteMetadata.games.map((game, i) => (
        <GameDetails game={game} inverted={!!(i % 2)} />
      ))}

      <Featured imagePath="featured/apply-now.jpg" large>
        <FeaturedDetails
          title="Work on fully charged projects with cool people"
          description="Should you be here with us?"
          callToAction={<CallToAction text="Apply now!" href="/careers/" />}
        />
      </Featured>

      <SEO title="Games" />
    </Layout>
  )
}

export default withStyles(styles)(GamesPage)
